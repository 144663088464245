import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import ReactMarkdown from 'react-markdown'

import ContentWrapper from '../ContentWrapper'

import * as s from './style.module.sass'

const MissionManager = () => {
  const data = useStaticQuery(graphql`
    query MissionManagerQuery {
      markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
        frontmatter {
          missionManager {
            image {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            name
            role
            title
            text
            boldText
          }
        }
      }
    }
  `)

  if (!data) return null
  const { image, title, boldText } = data.markdownRemark?.frontmatter?.missionManager

  return (
    <ContentWrapper>
      <div className={s.wrapper}>
        <div className={s.image} style={{ backgroundImage: `url(${image?.childImageSharp?.fluid?.src})` }} />
        <div className={s.textWrapper}>
          <h1>
            <ReactMarkdown>{title}</ReactMarkdown>
          </h1>
          <div className={s.text}>
            <strong>{boldText}</strong>
          </div>
        </div>
      </div>
    </ContentWrapper>
  )
}

export default MissionManager
