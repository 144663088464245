import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import { Helmet } from 'react-helmet'

import Layout from '../components/Layout'
import Hero from '../components/Hero'
import Partners from '../components/Partners'
import Steps from '../components/Steps'
import Sectors from '../components/Sectors'
import IAMissionManager from '../components/IAMissionManager'
import MissionManager from '../components/MissionManager'
import Testimonials from '../components/Testimonials'
import JoinUs from '../components/JoinUs'

// eslint-disable-next-line
export const IndexPageTemplate = ({
  title,
  heroImage,
  subTitle
}) => (
  <>
    <Hero
      title={title}
      subTitle={subTitle}
      image={heroImage}
      withSearchBar
    />
    <Partners />
    <Steps />
    <Sectors />
    <IAMissionManager />
    <MissionManager />
    <Testimonials />
    <JoinUs
      title='Vous êtes ingénieur ?'
      subTitle='Rejoignez-nous, étendez votre réseau et développez vos expériences'
      link={`${process.env.GATSBY_APP_URL}/signup?pType=FREELANCE`}
      linkText='Se lancer sur Artelink'
    />
  </>
)

IndexPageTemplate.propTypes = {
  title: PropTypes.string,
  heroImage: PropTypes.object,
  subheading: PropTypes.string
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout transparentHeader>
      <Helmet>
        <title>La plateforme regroupant les meilleurs experts freelances de l’ingénierie</title>
        <meta name="description" content="Avec Artelink, trouvez en quelques clics l’expert de l’ingénierie qu’il vous faut parmi plusieurs centaines de profils testés et approuvés." />
        {/* Open Graph */}
        <meta name="og:title" content="Artelink - La plateforme regroupant les meilleurs experts freelances de l’ingénierie" />
        <meta name="og:image" content="" />
        <meta name="og:url" content="" />
        <meta name="og:description" content="Avec Artelink, trouvez en quelques clics l’expert de l’ingénierie qu’il vous faut parmi plusieurs centaines de profils testés et approuvés." />
      </Helmet>

      <IndexPageTemplate
        title={frontmatter.title}
        subTitle={frontmatter.subTitle}
        heroImage={frontmatter.heroImage}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        subTitle
        heroImage {
          childImageSharp {
            gatsbyImageData
          }
        }
        subheading
      }
    }
  }
`
