import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import ReactMarkdown from 'react-markdown'

import * as styles from './style.module.sass'

import Icon from '../Icon'
import ContentWrapper from '../ContentWrapper'
import DidYouKnow from '../DidYouKnow';

const IAMMissionManager = () => {
  const data = useStaticQuery(graphql`
    {
      markdownRemark(frontmatter: { componentKey: { eq: "IAMissionManager" } }) {
        frontmatter {
					title
					argumentTitle
					argumentsList {
						text
					}
					title2
					description2
					profilesMissionManager {
						image {
							publicURL
						}
          }
          information
          informationButton
        }
      }
    }
  `)
  const {
		title,
		argumentTitle,
		argumentsList,
		title2,
		description2,
    profilesMissionManager,
    information,
    informationButton
	} = data.markdownRemark.frontmatter

  return (
    <div>
      <div className={`${styles.container}`}>
        <div className={styles.upperPart}>
          <ContentWrapper style={styles.leftContent}>
            <div className={styles.argumentContainer}>
              <h2 className={styles.title}><ReactMarkdown>{title}</ReactMarkdown></h2>

              <p className={styles.argumentTitle}>{argumentTitle}</p>

              <ul className={styles.argumentsList}>
                {
                  argumentsList.map((argument, i) => (
                    <li className={styles.argument} key={i}>
                      <Icon icon="rightArrow" iconSize="small" containerSize="small" />
                      <p className={styles.argumentText}>{argument.text}</p>
                    </li>
                  ))
                }
              </ul>
              <div className={styles.block2}>
                <h3>{title2}</h3>
                <p>{description2}</p>
              </div>
            </div>

          </ContentWrapper>

          <div className={`${styles.profilesContainer}`}>
            {
              profilesMissionManager.map((manager, index) => (
                <img key={index} src={manager.image.publicURL} alt={`profile-${index}`} className={styles[`profile${index}`]}/>
                ))
              }
          </div>
        </div>

        <ContentWrapper>
          <div className={styles.block2Mobile}>
            <h3>{title2}</h3>
            <p>{description2}</p>
          </div>
        </ContentWrapper>

      </div>

      <div className={styles.darkBlueSection}>
        <DidYouKnow text={information} cta={informationButton} link='/selection' />
      </div>
    </div>
  )
}

export default IAMMissionManager
