import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import ReactMarkdown from 'react-markdown'

import ContentWrapper from '../ContentWrapper'
import Step from '../Step'
import Button from '../Button'

import * as styles from './style.module.sass'

const Steps = () => {
  const data = useStaticQuery(graphql`
    {
      markdownRemark(frontmatter: { componentKey: { eq: "steps" } }) {
        frontmatter {
          title
          steps {
            title
            description
            icon {
              publicURL
            }
          }
        }
      }
    }
  `)
  const { title, steps } = data.markdownRemark.frontmatter

  return (
      <ContentWrapper>
        <h1 className={styles.title}><ReactMarkdown>{title}</ReactMarkdown></h1>

        <div className={`${styles.stepsContainer} columns`}>
          {
            steps.map((step, index) => (
              <Step position={index + 1} key={index} title={step.title} message={step.description} icon={step.icon} />
            ))
          }
        </div>

        <div className={styles.buttonContainer}>
          <Link to='/fonctionnement'>
            <Button
              icon="rightArrow"
              iconSize={'small'}
              containerSize={'normal'}
              width="100%"
            >
              Découvrir notre fonctionnement en détails
            </Button>
          </Link>
        </div>
      </ContentWrapper>
  )
}

export default Steps
