// extracted by mini-css-extract-plugin
export var container = "style-module--container--2FRk2";
export var title = "style-module--title--y05eX";
export var leftContent = "style-module--leftContent--SkJoI";
export var argumentContainer = "style-module--argumentContainer--nWE5S";
export var argumentTitle = "style-module--argumentTitle--3Ydnj";
export var argumentsList = "style-module--argumentsList--gXW8F";
export var argument = "style-module--argument--PDRGa";
export var argumentText = "style-module--argumentText--aliOL";
export var block2Mobile = "style-module--block2Mobile--IvEBx";
export var upperPart = "style-module--upperPart--wzf7j";
export var block2 = "style-module--block2--lcD3S";
export var darkBlueSection = "style-module--darkBlueSection--Fdunk";
export var profilesContainer = "style-module--profilesContainer--0-Je9";
export var profile0 = "style-module--profile-0--nRZGq";
export var profile1 = "style-module--profile-1--KgM-Q";
export var profile2 = "style-module--profile-2---1m6P";
export var profile3 = "style-module--profile-3--VziT0";
export var informationBlock = "style-module--informationBlock--Jsu7V";
export var informationContainer = "style-module--informationContainer--sZ3gC";
export var buttonContainer = "style-module--buttonContainer--wN9Jj";
export var button = "style-module--button--V-T1q";
export var buttonText = "style-module--buttonText--61uSf";